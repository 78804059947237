@media (max-width: 768px) {
  .bodyContainer {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: auto;
    left: 0 !important;
    margin: 0 !important;
    padding: 10px 5px 10px 5px !important;
  }

  .leftBodyContainer {
    width: 100% !important;
    height: auto !important;
    top: -120px;
    margin-right: 0;
    margin-left: 0 !important;
    padding: 10px !important;
  }
  .rightBodyContainer {
    position: relative;
    top: -120px;
    width: 100% !important;
    height:auto !important;
    padding: 10px 0px 10px 5px !important;
    margin-right: 0;
    margin-left: 0 !important;
  }
}

.root-container{
  height: 100%;
}

.bodyContainer {
  position: relative;
  height: auto; 
  display: flex;
  left: 20px;
  margin: 20px;
  padding: 10px;
}

.leftBodyContainer {
  position: relative;
  width: 25%;
  padding: 10px 30px;
  margin-right: 10px; 
  top: -120px;
}

.rightBodyContainer {
  width: 75%;
  padding: 10px;
  margin-left: 28px; 
  margin-right: 10px;
}
