@media (max-width: 768px) {
  .footer {
    margin-top: -100px !important;
  }
}

h3{
  padding-top:10px;
}

.footer {
  position: relative;
  bottom: 0;
  margin-bottom: -25px;
  width: 100%;
}

.boutique{
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  padding-top: 5px;
}

.footer_container hr{
  width: 100%;
  height: 2px;
  background-color: #323232;
}

.footerEnd{
  display: flex;
  height: 70px;
  align-items: center;
  justify-content: center;
  background-color: #323232;
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0.07038461416959763px;
}

.footer_profile{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 40px 0;
  padding-top: 20px;
}

.footerEndDarzee{
  width: 50px;
  height: auto;
  margin-right:10px;
}

.footerEndIndia{
  width: 30px;
  height: auto;
  margin: 0 10px;
}
