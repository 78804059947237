
.container-mobile {
    max-width: 400px;
    height:578px;
    margin: 0;
    padding: 0;
}
  
   
  .swiper_container-mobile {
    position: relative; 
  }
  
  
  @media (max-width: 768px) {
    .swiper_container {
      height: 500px;
    }
  }
  

  .swiper-pagination-bullet-active {
    background-color: white !important;
    opacity:100% !important;
    z-index: 100; 
  }
  .swiper-pagination{
    margin: 25px -5px;
    z-index: 1000000;
  }
  .custom-bullets {
    top:20px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
  }
  
  .custom-bullet {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #ccc;
    opacity: 0.5;
    margin: 0 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  
  }
  
  .custom-bullet.active {
    opacity: 1 !important;
    background-color: #fff;
  }