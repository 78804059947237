@media (max-width: 768px) {
  .team-item {
    background: none !important; 
    width: 100% !important;
    margin: auto; 
    height: auto !important;
  }
}

.team-item {
    position : relative;
    background: white;
    text-align: center;
    padding: 50px 0px 5px 0px;
    border-radius: 8px;
    width: 322px;
    border-radius: 10px;
    z-index: 10;
  }
  
  
 
  ul.team-icon {
      margin-top: 25px;
  }
  
  ul.team-icon li {
      display: flex;
      margin: 0 3px;
      transition: all 0.25s ease-in-out;
  }
  
  ul.team-icon li a {
      margin: 12px 0 0;
      display: inline-block;
      width: 34px;
      height: 34px;
      font-size: 18px;
      line-height: 18px;
      color: #fff;
  }
  
  
  .team-icon li a:hover {
      color: #dedede;
      transition: all 0.25s ease-in-out;
  }
  
  
  .team-item h3 {
    margin: 20px 0 1px 0;
    font-family: Inter;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
  }

  .team-info {
    display: block;
    margin-bottom: 0;
  }
  
  .team-info {
    color: #323232;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0em;
  }
  
  .team-img-container {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 50%; /* Half of the width/height to make it a circle */
}

.team-img {
    width: 100px; 
    height: 100px;
    object-fit: cover; 
    border: 2px solid #ffffff; 
    box-shadow: 0px 2px 15px 1px rgba(0,0,0,0.7);
    border-radius: 50%;
}


  ul.team-icon {
    margin-top: 25px;
    list-style-type: none;
    padding: 0;
  }
  
  
  
  ul.team-icon li img {
    display: flex;
    width: 32px;
    height: 32px;
    margin: auto;
  }
  
  .location {
    display: inline-block;
    margin-top: 10px;
    font-size: 14px;
    color: #888;
  }
  
  .location i {
    margin-right: 4px;
    color: #777;
  }
  
  ul.team-icon {
    display: flex;
    justify-content: space-between;
    width: fit-content;
    height: fit-content;
    gap: 27px;
    margin: auto;
  }
  
  .para{
    display: flex;
    color: #323232;
    opacity: 0.95;
    margin: 12px 0 20px 0;
    padding: 8px;
    height: auto;
    justify-content: center;
    font-family: Inter;
    font-size: 13px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: -0.01em;
    text-align: center;
  }