body, h1, h2, h3, p, ul, li {
	margin: 0;
	padding: 0;
  }



@media (max-width: 1120.12px) {
	.row {
		max-width: 322px;
		margin-left: auto;
		margin-right: auto;
	}
}
