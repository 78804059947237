@media (max-width: 768px) {
  .filters-apply{
    width: 100% !important;
  }
  .clothfilters{
    justify-content: center;
  }
  .mobile-view{
    width: 100% !important;
  }
  .filer-item{
    marginLeft: 0 !important;
    width: auto !important;
  }
}

hr{
  margin: 4px 0;
  position: relative;
}

.clothfilters > h5{
  width : 90%
}

h6{
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  color: #323232;
}

.clothfilters {
  position: relative;
  width: 92%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  overflow-x: auto;
  overflow-y: hidden;
  gap: 4px;
  margin-top: 10px;
}

.checkbox-label{
  display: flex;
  align-items: center;
  gap: 5px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  color: #323232;
  text-align: center;

}
.filters-title{
  margin-right:150px;
}

.filters-title-collapse{
  display:flex;
  flex-direction:row-reverse;
  width:20%;
  position:relative;
  right:24px;
}

.columns{
  margin-top:20px;
  justify-content: space-between;
}

.columns .column:first-child {
  margin-right: 30px;
}

.column {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
}

.is-collapsed {
  display: none !important; 
}

.color-circle-container {
  display: flex;
  flex-wrap: wrap;
}

.color-circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: 5px;
  cursor: pointer;
  transition: transform 0.2s ease;
  padding: 3px;
}

.selected {
  border: 1px solid #323232;
  transform: scale(1.15);  
}


.horizontal-scroll {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
}

.filter-item {
  display: flex;
  white-space: nowrap;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  background-color: #fff; 
  color: #323232;
  width: 120%;
  margin-left:10px;
  max-height: 33px;
  padding: 5px 15px 5px 15px;
  border-radius: 15px;
  border: 1px solid #4D7AFF;
  transition: background-color 0.3s, color 0.3s;
  cursor: pointer;
}

.clothfilters::-webkit-scrollbar {
  display: none;
}

.filter-item.active {
  background-color: #323232; /* Active background color */
  color: #fff; /* Active text color */
}

.horizontal-scroll::before,
.horizontal-scroll::after {
  content: "";
}

.horizontal-scroll::-webkit-scrollbar {
  display: none;
}

.filters-apply{
  width:80%;
}
