.header_container {
  width: 100%;
  height: 200px; 
  position: relative;
  overflow: hidden;
}

.header_container img {
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  object-fit: cover;
  z-index: 1;
}
