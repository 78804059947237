.mobile-outfit-container{
  width: 100%;
  scroll-behavior: smooth; 
}

.mobile-outfit-container::-webkit-scrollbar {
  display: none;
}
.react-responsive-modal-closeButton{
  z-index: 1000;
}

.mobile-outfit-view {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  position: relative;
  margin-top: 16px;
  padding: 0 17.5px;
  scroll-snap-align: initial; 
  align-items: center;
}


.mobileOutfit-title{
  position: relative;
  width:100%;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0.07038461416959763px;
  text-align: center;
  justify-content: space-between;
  color: #FFFFFF;
  padding: 30px 20px 20px 20px;
  background-color: #000000;
}

.mobileOutfit-title > img{
  width: 24px;
  height: 24px
}


.card-mobileoutfit {
    position: relative;
    top: 20px;
    max-width: 380px;
    height: 710px;
    overflow: hidden;
    margin-bottom: 20px;
    border:none;
    border-radius:7px;
}

.card-details-mobileoutfit{
  top: 30px;
  margin-bottom: 15px;
}

.card-mobileoutfit-title{
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
}

.card-mobileoutfit-date{
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  opacity: 0.5;
}

.react-responsive-modal-closeButton path {
  fill: white; 
}

.FiltersComponent {
  display: flex;
  flex-direction: column;
  color: #000000;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white; 
  padding: 10px; 
  z-index: 100; 
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.1);
}

.FiltersComponentClose{
  position: absolute;
  left: auto;
  right: 16px;
}

.FiltersComponentClose > img{
  width: 24px;
  height: 24px;
  zIndex: 1000;
}

.FiltersComponent-content{
  width: 100%;
  margin: 0 auto;
  padding-left: 10px;
}
.mobile-outfit-view-footer{
  position: relative;
  margin-top: 130px;
}