
/* Media query for desktop view */
  @media (min-width: 768px) {
    .card {
      width: calc(23% - 22px) !important;; 
    }
    .card-img-top {
      height: 320px !important; 
    }
  }

.subOutfit {
  display: inline-block; 
  vertical-align: top; 
  margin-bottom: 10px;
}

.subOutfit .rightProfileHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0.07038461416959763px;
  text-align: left;
  flex-wrap: wrap;
  margin: 10px 0px;
  width: 100%;
}

.rightProfileHeaderContent{
  color: #4D7AFF;
  marginRight: 100px;
}

.subOutfit .expand-button {
  margin: 10px 0 20px 0;
  display: flex;
  justify-content: center;
  cursor: pointer;
  font-size: 20px;
}

.subOutfit .card-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  gap: 10px;
}


.rightProfile {
  display: flex;
  flex-direction: column;
  align-items: center;
  
}
.rightProfileItems {
  display: flex;
  flex-direction: column;
  margin: 0;
  width: 100%;
}


.card {
  width: calc(45% - 22px); 
  max-height: 405px;
  overflow: hidden;
  margin-right: 22px;
  margin-bottom: 20px;
  border:none;
}

.card-body{
  padding: 10px 0 0 0;
}

.card:hover {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  cursor: pointer;
}


.card-title{
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
}

.card-text{
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  color: #323232;
  opacity: 0.5;
}

.card-img-top {
  height: 260px; 
  max-height: 320px;
  border-radius:8px;
  object-fit: cover; 
  object-position: center;
}


.image-container {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
}

.image-button {
  background: none;
  border: none;
  font-size: 24px;
  color: #000;
  cursor: pointer;
  outline: none;
}

.custom-arrows{
  position: relative;
  height: 40px;
  width: 40px;
  align-self: center;
  object-fit: cover;
  object-position: center;
}
.ca-right{
  margin-left:100px;
}
.ca-left{
  margin-right:100px;
}

.image-button:hover {
  color: #5DADE2;
}
  

.card-scrollable {
  flex: 0 0 auto; 
  width: 180px; 
  margin-right: 7px; 
  overflow-x: scroll; 
}

.card-container-mobile{
  display: flex;
  width: 100%;
  flex-direction: row;
  margin-top: 10px;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
}

.card-container-mobile::-webkit-scrollbar {
  display: none;
}



/* Style for the modal content */
.custom-modal-class{
  display: flex !important;
  max-width: 100% !important;
  height: 100% !important;
  padding: 0px !important;
  margin: 0px !important;  
  overflow: auto; 
  background: rgba(0, 0, 0, 0.75) !important; 
  z-index: 1002; 
  position: relative;
}

.custom-modal-class-mobile{
  color: #fff;
  display: flex !important;
  height: 100% !important;
  padding: 0px !important;
  margin: 0px !important;  
  background: rgba(0, 0, 0, 1) !important; 
  position: relative;
}
