.rootdir{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.rootdir >img{
    width: 100px;
    height: 100px;
    object-fit: cover;
}